import { type StyleValue, ref } from 'vue';

import { safeLocalStorage } from '~/libs/safeLocalStorage';

import Tracker from '../libs/Tracker';
import { requestPermission } from '../libs/firebaseConfig';
import { mainService } from '../services/main';
import { usePwaStore } from '../services/pwa';

export function usePwa() {
  const isPwa = ref(false);
  const os = ref<'pc' | 'android' | 'ios' | ''>('pc');
  const pwaStore = usePwaStore();

  const install = () => {
    if (pwaStore.getDeferredPrompt) {
      pwaStore.getDeferredPrompt.prompt();
      Tracker['Click Bottom Sheet']({
        bottomSheetType: 'install',
        buttonType: 'confirm',
        buttonName: '설치없이 앱으로 보기',
      });
    } else {
      pwaStore.setShowPopup(true);
    }
  };

  onMounted(() => {
    isPwa.value = window.matchMedia('(display-mode: standalone)').matches;
    // isPwa.value = true;
    os.value = getOS();
  });

  return { isPwa, os, install };
}

function getOS() {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.includes(platform) || windowsPlatforms.includes(platform)) {
    return 'pc';
  }
  if (iosPlatforms.includes(platform)) {
    return 'ios';
  }
  if (/Android/.test(userAgent)) {
    return 'android';
  }
  return '';
}

export function useNotificationPermission() {
  const permission = ref('');
  const origin = ref('');

  onMounted(() => {
    if ($isClient()) {
      if (!('Notification' in window)) {
        console.log('This browser does not support notification');
        permission.value = '';
        origin.value = '';
        return;
      }
      permission.value = Notification?.permission || '';
      origin.value = window.location.origin;
    }
  });

  onUnmounted(() => {
    permission.value = '';
  });

  return { permission, origin };
}

const deniedPermissionAlert = async () => {
  Tracker['View Popup']({
    popupType: 'complete_follow',
  });
  if (
    await $customAlert({
      title: '팔로우 완료',
      message: '팔로우 멤버의 새글 소식을 받고 싶다면,\n[설정 > 알림]에서 알림을 허용해 주세요.',
    })
  ) {
    Tracker['Click Popup']({
      popupType: 'complete_follow',
      buttonType: 'confirm',
      buttonName: '확인',
    });
  }
};

export function useRequestNotificationPermissionByFollowing() {
  const { isPwa, os } = usePwa();
  const pwaStore = usePwaStore();

  const confirmButtonStyle: StyleValue = {
    fontFamily: 'Pretendard',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 22,
    textAlign: 'center',
  };

  const showConfirm = async () => {
    if (isPwa.value && !pwaStore.isAskedNotificationPermission) {
      if (Notification.permission === 'default') {
        Tracker['View Popup']({
          popupType: 'set_notification',
        });
        if (
          await $customConfirm({
            title: '알림을 켜주세요!',
            message: '팔로우한 회원의 새글 소식을 알려드릴까요?',
            leftButtonLabel: '아니오',
            rightButtonLabel: '네, 알려주세요',
            leftButtonStyle: {
              color: '#788194',
              ...confirmButtonStyle,
            },
            rightButtonStyle: {
              color: '#1F5AF2',
              ...confirmButtonStyle,
            },
          })
        ) {
          Tracker['Click Popup']({
            popupType: 'set_notification',
            buttonType: 'confirm',
            buttonName: '네, 알려주세요',
          });
          const token = await requestPermission();
          if (token) {
            await mainService.postUserDeviceToken({
              deviceToken: token,
              os: os.value,
              isPwa: isPwa.value,
            });
          } else {
            /**
             * IOS에서 발생하는 케이스
             * 1. 알림 권한을 허용한다.
             * 2. 시스템 설정에 가서 알림 권한을 끈다.
             * 3. 앱을 재시작 후 Notification.permission을 확인해보면, 거부되어있는 데도 불구하고  'default'로 나온다.
             *  */
            await deniedPermissionAlert();
          }
        } else {
          Tracker['Click Popup']({
            popupType: 'set_notification',
            buttonType: 'cancel',
            buttonName: '아니오',
          });
        }
      } else if (Notification.permission === 'denied') {
        await deniedPermissionAlert();
      }
      pwaStore.setIsAskedNotificationPermission(true);
    }
  };
  return { showConfirm };
}
// updateShowNotificationPopup
export function updatePWAPopupPeriod(key: string, days?: number) {
  const date = new Date();
  const period = days || 5;
  date.setDate(date.getDate() + period);
  // date.setMinutes(date.getMinutes() + period);
  safeLocalStorage.setItem(key, 'true', date);
}
