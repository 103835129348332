interface State {
  deferredPrompt: any;
  isFirstLoaded: boolean; // 첫 로드인지 여부
  showPopup: boolean; // 설치 팝업을 보여줄지 여부
  showInstallBottomModal: boolean; // 설치팝업 open 여부를 제어하는 flag 값
  isAskedNotificationPermission: boolean; // 알림 권한을 물어본 적이 있는지 여부
}

export const usePwaStore = defineStore('pwa', {
  state: (): State => ({
    deferredPrompt: null,
    isFirstLoaded: false,
    showPopup: false,
    showInstallBottomModal: false,
    isAskedNotificationPermission: false,
  }),
  actions: {
    setDeferredPrompt(deferredPrompt: any) {
      this.deferredPrompt = deferredPrompt;
    },
    setIsFirstLoaded(isFirstLoaded: boolean) {
      this.isFirstLoaded = isFirstLoaded;
    },
    setShowPopup(showPopup: boolean) {
      this.showPopup = showPopup;
    },
    setIsAskedNotificationPermission(isAskedNotificationPermission: boolean) {
      this.isAskedNotificationPermission = isAskedNotificationPermission;
    },
  },
  getters: {
    getDeferredPrompt({ deferredPrompt }) {
      return deferredPrompt;
    },
    getIsFirstLoaded({ isFirstLoaded }) {
      return isFirstLoaded;
    },
    getShowInstallBottomModal({ showInstallBottomModal }) {
      return showInstallBottomModal;
    },
  },
});
